<template>
  <div>
    <v-card width="100%" elevation="3" class="px-9 pt-10 pb-14 mb-12" v-if="site_config.management_contacts">
      <v-card-title style="font-size: 2rem; line-height: 2rem; color: #616161" class="py-2">
        {{ site_config.management_contacts.title }}
      </v-card-title>
      <v-card-text class="pt-5" style="font-size: 1.1rem; line-height: 1.8rem; width: 80%" v-if="site_config.management_contacts.text">
        {{ site_config.management_contacts.text }}
      </v-card-text>
      <v-card-text class="mt-2" v-if="site_config.management_contacts.show_buttons">
        <v-btn :color="accent_color" class="py-3 px-4 mr-3"
               :to="{ name: 'SiteManagement', params: { organization_slug: $route.params.organization_slug } }"
               :dark="site_config.management_contacts.white_button_text"
        >
          Управління
        </v-btn>
        <v-btn :color="accent_color" class="py-3 px-8"
               :to="{ name: 'SiteDocuments', params: { organization_slug: $route.params.organization_slug } }"
               :dark="site_config.management_contacts.white_button_text"
        >
          Документи
        </v-btn>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col cols="12">
        <v-card class="px-8 pt-6 mb-7">
          <div class="px-8">
            <v-row>
              <v-col cols="12" md="6">
                <div style="font-size: 1.2rem; font-weight: 500; text-transform: uppercase">Адреса</div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="contact-wrapper mb-3 pa-4 grey lighten-4">
                  <div class="d-flex mt-1 contact-value">
                    <v-icon :color="accent_color" class="mr-2">mdi-map-marker-check-outline</v-icon>
                    <span class="grey--text text--darken-1 contact-value-text">
                      {{ organization_data.organization_address || '' }}
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <template v-if="organization_data.contacts">
          <v-card class="px-8 pt-6 mb-7" v-if="organization_data.contacts.self_contacts">
            <div class="px-8">
              <v-row>
                <v-col cols="12" md="6">
                  <div style="font-size: 1.2rem; font-weight: 500; text-transform: uppercase">
                    Наші контакти
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="contact-wrapper mb-3 pa-4 grey lighten-4"
                       v-for="(item, idx) in organization_data.contacts.self_contacts" :key="idx"
                  >
                    <div class="contact-name font-weight-medium">
                      {{ item.position }}
                    </div>
                    <div class="d-flex mt-1 contact-value">
                      <v-icon :color="accent_color" class="mr-2">mdi-phone</v-icon>
                      <span class="grey--text text--darken-1 contact-value-text">{{ item.value }}</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <v-card class="px-8 pt-6 mb-7" v-if="organization_data.contacts.other_contacts">
            <div class="px-8">
              <v-row>
                <v-col cols="12" md="6">
                  <div style="font-size: 1.2rem; font-weight: 500; text-transform: uppercase">
                    Корисні контакти
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="contact-wrapper mb-3 pa-4 grey lighten-4"
                       v-for="(item, idx) in organization_data.contacts.other_contacts" :key="idx"
                  >
                    <div class="contact-name font-weight-medium">
                      {{ item.name }}
                    </div>
                    <div class="d-flex mt-1 contact-value">
                      <v-icon :color="accent_color" class="mr-2">mdi-phone</v-icon>
                      <span class="grey--text text--darken-1 contact-value-text">{{ item.value }}</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {check_show_page} from "@/utils/icons";

export default {
  name: "Contacts",
  computed: {
    ...mapGetters({
      organization_data: 'getSiteOrganization',
      site_config: 'getSiteConfig'
    }),
    accent_color() {
      return this.site_config.site_color || 'secondary'
    },
    accent_color_hash() {
      return this.site_config.site_color_hash || '#f18a0d'
    }
  },
  created() {
    const check = check_show_page(this.$route.name, this.site_config)
    if (!check) {
      this.$router.push({ name: 'SiteBlocked' })
    }
  }
}
</script>

<style scoped lang="scss">
  .contact-wrapper {
    margin-bottom: 9px;
    padding: 12px;

    .contact-name {
      font-size: 1.06rem;
      text-transform: uppercase;
      font-weight: 500
    }
    .contact-value {
      .contact-value-text {
        font-size: 1.1rem
      }
    }
  }
</style>